import AboutMe from "../components/AboutMe";
import Hero from "../components/Hero";

export default function Index(props) {
  return (
    <>
      <Hero />
      <AboutMe />
    </>
  );
}
